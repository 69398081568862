@font-face {
    font-family: 'dvlavrm';
    src: url('../fonts/dvla/dvlavrm-webfont.eot');
    src: url('../fonts/dvla/dvlavrm-webfont.eot') format('embedded-opentype'),
    url('../fonts/dvla/dvlavrm-webfont.woff2') format('woff2'),
    url('../fonts/dvla/dvlavrm-webfont.woff') format('woff'),
    url('../fonts/dvla/dvlavrm-webfont.ttf') format('truetype'),
    url('../fonts/dvla/dvlavrm-webfont.svg#dvla_-_vrmregular') format('svg');
    font-weight: normal;
    font-style: normal
}

html {
    scroll-behavior: smooth;
}

#jsd-widget {
    margin-bottom: 3em;
}

.tab-card-header {
    background: var(--bs-card-cap-bg) !important;
}

ng-select.form-control.is-invalid {
    border-color: var(--bs-form-invalid-border-color) !important;

    .ng-arrow-wrapper {
        padding-right: 3em !important;
    }
}

mat-cell {
    cursor: pointer;
}

ng-select.form-control {
    padding-right: 0 !important;

    &.ng-select-disabled {
        background-color: #e9ecef;
        opacity: 1;

        .ng-select-container {
            background-color: #e9ecef;
        }
    }

    .ng-select-container {
        background-color: transparent !important;
        height: auto !important;
        min-height: 0;
        border: none !important;
        box-shadow: none !important;

        .ng-value-container {
            padding-left: 0;

            .ng-input {
                top: auto !important;
                padding-left: 0 !important;
            }
        }
    }

    .ng-dropdown-panel {
        min-width: 10rem;
        padding: 0.5rem 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        z-index: 999999999999 !important;

        .ng-option.ng-option-marked {
            color: #16181b;
            text-decoration: none;
            //background-color: #f8f9fa;
        }

        .ng-option.ng-option-marked {
            font-weight: normal;
            color: #fff;
            background-color: var(--bs-primary);
        }

        .ng-option.ng-option-selected {
            font-weight: normal;
            color: #fff;
            text-decoration: none;
            background-color: var(--bs-primary);
        }

        .ng-option.ng-option-selected,
        .ng-option.ng-option-selected.ng-option-marked {
            text-decoration: none !important;
        }
    }
}

html.dark-theme {
    // sweetalert
    div:where(.swal2-container) div:where(.swal2-popup) {
        background-color: $body-bg-dark !important;
    }

    div:where(.swal2-container) .swal2-html-container {
        color: $body-secondary-color-dark !important;
    }

    .authentication-header {
        background-color: initial;
    }

    ng-select.form-control {
        .ng-select-container,
        &.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
            color: initial !important;
        }

        .ng-value-label {
            // NOTE: Not sure why this was added? Possible to delete maybe?
            //color: #c0c8d1;
        }

        &.ng-select-disabled {
            background-color: rgba(255, 255, 255, 0.22);
            opacity: 1;

            .ng-select-container {
                color: #c0c8d1;
                background-color: #1e2129;
            }
        }

        .ng-dropdown-panel {
            background-color: #1e2129;

            .ng-dropdown-panel-items {
                .ng-option {
                    color: #c0c8d1;
                    background-color: #1e2129;
                }
            }

            .ng-option.ng-option-marked {
                color: #e4e5e6;
            }

            .ng-option.ng-option-marked {
                font-weight: normal;
                background-color: var(--bs-primary-border-subtle);
            }

            .ng-option.ng-option-selected {
                color: #fff;
                background-color: var(--bs-primary);
            }
        }
    }
}

.license-plate {
    padding: .3em .5em;
    font-size: 24px;
    font-family: 'dvlavrm', monospace;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;
    background: #f9cd27;
    color: black;
    text-transform: uppercase;
    width: 110px;
    min-width: 110px;
    max-height: 32px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-kerning: auto;
    letter-spacing: -0.01em;
    word-spacing: 1px;
    border-radius: 4px;
}

.license-plate-sm {
    display: block;
    width: 96px !important;
    padding: 0em .5em;
    font-size: 1.2rem !important;
    font-family: 'dvlavrm', monospace;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;
    background: #f9cd27;
    color: black;
    text-transform: uppercase;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-kerning: auto;
    letter-spacing: -0.01em;
    word-spacing: 1px;
    border-radius: 4px;
}

.electric {
    border-left: 0.7em solid #73b12a;
    padding-left: 0.2em;
}

.user-circle {
    display: inline-block;
    background-color: var(--bs-form-invalid-color);
    margin: 10px;
    border-radius: 50%;
}

.user-circle-inner {
    color: #fff !important;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    height: 40px;
    width: 40px;
    font-size: 18px;
}

.ng-scroll-content {
    margin-top: -.5em;
}

.required::after {
    content: ' *';
    font-weight: bold;
    color: var(--bs-form-invalid-color);
}

.modal {
    z-index: 1060 !important;
}

.hr-text {
    line-height: 1em;
    position: relative;
    margin: 3em 4em;
    outline: 0;
    border: 0;
    color: transparent;
    text-align: center;
    height: 1.5em !important;
    opacity: 1;
}

.hr-text:before {
    content: '';
    background: linear-gradient(to right, #e9e9e9, #999, #e9e9e9);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 .5em;
    line-height: 1.5em;
    color: #999;
    background-color: #fff;
}

.modal-xxl .modal-dialog {
    max-width: 85% !important;
}

.z-1060 {
    z-index: 1060 !important;
}

.z-2000 {
    z-index: 2000 !important;
}

// FROM styles.scss
// Not all of these are needed/wanted
body {
    font-size: 14px;
    color: #525f7f;
    background-color: #f8f9fe;
}

.content-section {
    margin: 0 auto;
    display: block;
}

.header button {
    font-size: 15px;
    font-weight: bold;
}

.header .mat-select-value-text {
    color: #ffffff !important;
}

.header .mat-select-arrow {
    color: rgba(255, 255, 255, 1) !important;
}

.mat-menu-panel {
    margin-top: 18px;
    border: 1px solid #ccced2;
}

.sub_header {
    background: #ffffff;
    border-bottom: 1px solid #cccccc;
    padding: 10px 15px 15px;
    width: 100%;
    margin-top: 60px;
}

.sub_text_heading {
    color: #000000;
    font-size: 1.2em;
    font-weight: 500;
}

.sub_text_normal {
    text-align: right;
    color: #666666;
}

.user_name {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    font-size: 12px;
}

.user_name div {
    padding-top: 6px;
}

.setting-icon {
    background: #ee99a3;
    color: #4d1219;
    font-size: .6875rem !important;
    font-weight: bold;
    height: 32px;
    line-height: .8125rem;
    width: 32px;
    border: none;
    border-radius: 50%;
}

.setting-icon:focus, .mat-menu-item:focus {
    outline: none;
}

.noheader .md-form.md-outline.form-lg label {
    font-size: 14px !important;
}

.dashboard-form .mat-radio-button.mat-accent .mat-radio-inner-circle, .dashboard-form .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #757575;
}

.dashboard-form .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #757575;
}

.noheader .md-form.md-outline label {
    z-index: -1;
}

.noheader .md-form.md-outline label.active {
    z-index: 10;
}

.custom-container {
    width: 938px;
    margin: 0 auto;
}

.custom-container2 {
    width: 65%;
    margin: 0 auto;
}

.contact-list .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background: #007ecb;
}

.contact-list .mat-checkbox-checked.mat-accent .mat-checkbox-background, .contact-list .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background: #007ecb;
}

/* media query */
@media screen and (max-width: 1500px) {
    .content-section {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .sub_text_heading {
        text-align: center;
    }

    .sub_text_normal {
        text-align: center;
    }

    .setting-icon {
        background: #007ecb;
    }

    .dashboard-form .mobile-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .dashboard-form .border-right {
        border-right: none !important;
    }

    .company-details td, .display-certificate {
        display: block;
        width: 100% !important;
        float: left;
    }
}

@media screen and (max-width: 479px) {
    .noheader {
        width: 90% !important;
        margin: 5%;
        max-width: 370px;
    }

    .noheader .forgot_form {
        width: 100% !important;
    }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #2db5b9 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #2db5b9 !important;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #1c8699 !important;
}

.noDataInTable {
    text-align: center;
    font-size: 16px;
    color: #9f9f9f;
}

.mat-row:hover {
    background-color: rgb(191, 191, 191, 0.15);
}

.w-10 {
    width: 10%;
}

.w-7 {
    width: 7%;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #229ca9 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(34, 156, 169, .54) !important;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: rgba(34, 156, 169, .54) !important;
}

.mat-chip.mat-standard-chip.mat-chip-selected {
    background-color: #229ca9 !important;
}

.ng-option {
    text-align: left;
}

.quote-in-progress {
    background: $quote-in-progress;
}

.quote-pending-decision {
    background: $quote-pending-decision;
}

.quote-deposit-taken {
    background: $quote-deposit-taken;
}

.quote-sold {
    background: $quote-sold;
}

.quote-declined {
    background: $quote-declined;
}


.vehicle-in-stock {
    background: $vehicle-in-stock;
}

.vehicle-ex-stock {
    background: $vehicle-ex-stock;
}

.vehicle-sor-stock {
    background: $vehicle-sor-stock;
}

.vehicle-deposit-taken {
    background: $vehicle-deposit-taken;
}

.vehicle-awaiting-stock {
    background: $vehicle-awaiting-stock;
}

.vehicle-quote-item {
    background: $vehicle-quote-item;
}

.booking-client-appointment {
    background: $booking-client-appointment-background;
    text: $booking-client-appointment-text;
}

.booking-internal-appointment {
    background: $booking-internal-appointment-background;
    text: $booking-internal-appointment-text;
}

.booking-maintenance {
    background: $booking-maintenance-background;
    text: $booking-maintenance-text;
}

.booking-unavailability {
    background: $booking-unavailability-background;
    text: $booking-unavailability-text;
}

.page-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-light);
    content: "\f054";
}

.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      float: initial;
    }
  }
}

.ng-dropdown-panel.ng-select-bottom {
    padding-bottom: 0 !important;
}

.nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color) !important;
    pointer-events: none;
    cursor: default;
}


// PDF Styles
html.dark-theme #pdf {
    p {
        color: #212529 !important;
    }

    hr {
        border-color: rgba(33, 37, 41, 1) !important;
    }

    .text-muted {
        color: #6c757d !important;
    }

    .border {
        border-color: #dee2e6 !important;
    }

    .border-2 {
       border-width: initial !important;
    }

    .table > :not(caption) > * > * {
        color: #000000 !important;
    }

    thead, tbody, tfoot, tr, td, th {
        border-color: #dee2e6 !important;
    }
}
