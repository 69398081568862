dropzone > .dropzone.dz-wrapper {
    color: $input-color !important;
    //background-color: $input-bg !important;
}

dropzone > .dropzone.dz-wrapper .dz-message {
    margin: 0 !important;
    overflow: hidden !important;
    min-height: 100px !important;
    max-width: 100% !important;
    //border-color: $input-border-color !important;
    border-radius: $input-border-radius !important;
}


html.dark-theme dropzone > .dropzone.dz-wrapper .dz-message {
	color: #d1d7de;
    background-color: #3e464c;
    border-color: rgba(255, 255, 255, 0.35) !important;
}
