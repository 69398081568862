@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

@import "assets/scss/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "assets/scss/bootstrap-extended";
@import "assets/scss/app";
@import "assets/scss/header-colors";
@import "assets/scss/custom";
@import "assets/scss/dark-theme";
@import "assets/scss/dropzone";
@import "@ng-select/ng-select/themes/default.theme.css";
//@import 'dropzone/dist/min/dropzone.min.css';

@import "@sweetalert2/themes/bootstrap-4/bootstrap-4";

$theme-color-interval: 8% !default;

// NOTE: Fixes an issue with badge/pill text alignment
.badge {
    line-height: normal;
}

.dropdown-pointer::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #ffff;
    position: absolute;
    top: -6px;
    right: 16px;
    transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.list-group-item.disabled {
    color: var(--bs-list-group-disabled-color) !important;
    background-color: var(--bs-list-group-disabled-bg) !important;
}

.table th {
    padding: 0.5rem 0.5rem !important;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color))) !important;
    background-color: var(--bs-table-bg) !important;
    border-bottom-width: var(--bs-border-width) !important;
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))) !important;
}

// Position
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

// Toast
.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;

    .ngx-toastr {
        @include border-radius($alert-border-radius);
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 0.75rem 1.25rem 0.75rem 50px;
        width: 300px;
        background-position: 15px center;
        background-repeat: no-repeat;
        background-size: 24px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
        pointer-events: auto;

        .toast-title {
            font-weight: bold;
        }

        .toast-message {
            word-wrap: break-word;

        }

        .toast-close-button {
            position: relative;
            right: -0.3em;
            top: -0.3em;
            float: right;
            font-size: 20px;
            font-weight: bold;
            color: inherit;
            text-shadow: 0 1px 0 #fff;
            opacity: 0.5;
            background: transparent;
            border: 0;
            padding: 0;
        }

        .toast-progress {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 4px;
            background-color: #000000;
            opacity: 0.3;
        }

        .toast-close-button:hover,
        .toast-close-button:focus {
            color: #000000;
            text-decoration: none;
            cursor: pointer;
            opacity: 0.75;
        }
    }

    .ngx-toastr:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        opacity: 1;
        cursor: pointer;
    }
}

@function str-split($string, $separator) {
    $split-arr: ();
    // first index of separator in string
    $index: str-index($string, $separator);
    // loop through string
    @while $index != null {
        // get the substring from the first character to the separator
        $item: str-slice($string, 1, $index - 1);
        // push item to array
        $split-arr: append($split-arr, $item);
        // remove item and separator from string
        $string: str-slice($string, $index + 1);
        // find new index of separator
        $index: str-index($string, $separator);
    }
    // add the remaining string to list (the last item)
    $split-arr: append($split-arr, $string);

    @return $split-arr;
}

@function svg-factory($fill-color, $viewbox, $path) {
    $split: str-split($viewbox, ' ');
    $width: nth($split, 3);
    $height: nth($split, 4);

    // opacity is 0.9999 otherwise it uses a hex equivelent
    // firefox requires fill rgb
    @return "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='" + $viewbox +
    "' width='" + $width + "' height='" + $height + "'%3E%3Cpath fill='" +
    rgba($fill-color, 0.999999) + "' d='" + $path + "'/%3E%3C/svg%3E";
}

@function svg-encode($svg) {
    @return 'data:image/svg+xml;charset=utf8,' + $svg;
}

.toast-success {
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
    background-image: url(svg-encode(
            svg-factory(
                    shift-color($success, 6 * $theme-color-interval),
                    '0 0 512 512',
                    'M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'
            )
    ));
    color: shift-color($success, 6 * $theme-color-interval) !important;
    background-color: shift-color($success, -10 * $theme-color-interval);
    border: 1px solid shift-color($success, -9 * $theme-color-interval);
    // these are likely !important because we're overriding bootstrap 5's toast styles
    a {
        text-decoration: underline !important;
        color: shift-color($success, 10 * $theme-color-interval) !important;

        &:hover {
            color: shift-color($success, 12 * $theme-color-interval) !important;
        }
    }
}

.toast-error {
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
    background-image: url(svg-encode(
            svg-factory(
                    shift-color($danger, 6 * $theme-color-interval),
                    '0 0 512 512',
                    'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
            )
    ));
    color: shift-color($danger, 6 * $theme-color-interval) !important;
    background-color: shift-color($danger, -10 * $theme-color-interval);
    border: 1px solid shift-color($danger, -9 * $theme-color-interval);

    a {
        text-decoration: underline !important;
        color: shift-color($danger, 10 * $theme-color-interval) !important;

        &:hover {
            color: shift-color($danger, 12 * $theme-color-interval) !important;
        }
    }
}

.toast-info {
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
    background-image: url(svg-encode(
            svg-factory(
                    shift-color($info, 6 * $theme-color-interval),
                    '0 0 512 512',
                    'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'
            )
    ));
    color: shift-color($info, 6 * $theme-color-interval) !important;
    background-color: shift-color($info, -10 * $theme-color-interval);
    border: 1px solid shift-color($info, -9 * $theme-color-interval);

    a {
        text-decoration: underline !important;
        color: shift-color($info, 10 * $theme-color-interval) !important;

        &:hover {
            color: shift-color($info, 12 * $theme-color-interval) !important;
        }
    }
}

.toast-warning {
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
    background-image: url(svg-encode(
            svg-factory(
                    shift-color($warning, 6 * $theme-color-interval),
                    '0 0 576 512',
                    'M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'
            )
    ));
    color: shift-color($warning, 6 * $theme-color-interval) !important;
    background-color: shift-color($warning, -10 * $theme-color-interval);
    border: 1px solid shift-color($warning, -9 * $theme-color-interval);

    a {
        text-decoration: underline !important;
        color: shift-color($warning, 10 * $theme-color-interval) !important;

        &:hover {
            color: shift-color($warning, 12 * $theme-color-interval) !important;
        }
    }
}

// Responsive Design
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

ng-select.form-control {
    padding-right: 0 !important;

    .ng-select-container {
        //deze auto hoogte en geen border
        height: auto !important;
        min-height: 0;
        border: none;
        border-color: inherit;
        box-shadow: none !important;

        .ng-value-container {
            padding-left: 0;

            .ng-input {
                top: auto !important;
                padding-left: 0 !important;
            }
        }
    }
}

.ng-select-multiple.form-control {
    max-height: 38px !important;
}

.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0 !important;
}

.fs-8 {
    font-size: 0.8rem;
}
