$min-contrast-ratio: 3;
$primary: #299CA9FF;
$primary-accent: #EF5350;

// Vehicle Statuses
$vehicle-in-stock: #3B82F6;
$vehicle-ex-stock: #22C55E;
$vehicle-sor-stock: #EAB308;
$vehicle-deposit-taken: #A855F7;
$vehicle-awaiting-stock: #F97316;
$vehicle-quote-item: #64748B;

// Booking Statuses
$booking-client-appointment-background: #22C55E;
$booking-client-appointment-text: #F0FDF4;

$booking-internal-appointment-background: #A855F7;
$booking-internal-appointment-text: #FAF5FF;

$booking-maintenance-background: #EF4444;
$booking-maintenance-text: #FEF2F2;

$booking-unavailability-background: #64748B;
$booking-unavailability-text: #F8FAFC;

// Quote Statuses
$quote-in-progress: #3B82F6;
$quote-pending-decision: #A855F7;
$quote-deposit-taken: #F97316;
$quote-sold: #22C55E;
$quote-declined: #EF4444;
